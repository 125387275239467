
import React, { useEffect } from "react"
import {Header} from "./Header"
import {Footer} from "./Footer"
import styles from "./Layout.module.css";
import {useNavigate } from 'react-router-dom';

type Props = {
  children?: React.ReactNode
};

const Layout: React.FC<Props> = ({children}) => {
  let navigate = useNavigate();
  return (
    <>
      <div className={styles.homeScreenDiv}>
        <Header navigate={navigate}/>
          {children}
        <Footer />
      </div>
    </>
  )
}

export default Layout;