import { useOAuth } from "@services/Oauth";
import { FunctionComponent, useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { usersAtom } from "../../../src/state";
import styles from "./LandingPage.module.css";
import { Button, Divider } from "@mui/material";
import { Personal } from "@components/Personal";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useNavigate, useLocation } from "react-router-dom";
import Loader from "@components/Loader";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { Card } from "./elements/Card";
import axios from "axios";

import Web3 from "web3";
import { ABI, ADDRESS } from "@contracts/NFT";

import { makeStyles } from "@mui/styles";
import { addIPFSProxy, mergeDedupe } from "@utils/convert";

const useStyles = makeStyles({
  menu: {
    "& .MuiPaper-root": {
      backgroundColor: "#1E293B",
      color: "#fff",
    },
    "& .MuiMenuItem-root": {
      fontSize: 14,
    },
  },
});

//constant filer type
const FILTER: any = {
  All: "All",
  CDS: "Central",
  B2S: "B2S",
  TOPS: "Tops",
  CVERSE: "c-verse",
  METAWARDEN: "metawarden",
};

const Home = () => {
  const web3: any = new Web3("https://rpc.omplatform.com/");
  const user: any = useRecoilValue(usersAtom);
  const { getBalance, pushEvent, refreshToken } = useOAuth();

  const navigate = useNavigate();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [Image, setImage] = useState<any[]>([]);
  const [users, setUsers] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [contractAddress, setContractAddress] = useState<any[]>([]);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const [isRunning, setIsRunning]: any = useState(true);
  const [filter, setFilter] = useState<any>("All");

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goToRewards = () => {
    return false;
    navigate("/redeem");
  };

  //init
  useEffect(() => {
    const initContractFetchNFT = async (user: any) => {
      setUsers(user);
      setLoading(true);
      await getTokenUserActive(user);
    };

    if (user) initContractFetchNFT(user);
    setUsers(user);

    return () => {
      setImage([]);
      setContractAddress([]);
    };
  }, [user]);

  useEffect(() => {
    const initFetchNFT = async () => {
      setLoading(true);
      if (contractAddress) {
        let contractData: any = [];
        Promise.all(contractAddress.map(fetchNFT)).then(function (results) {
          const data = mergeDedupe(results);
          if (data.length > 0) setImage(data);      
        })
          // const data = mergeDedupe(results);
          // if (data.length > 0) setListNFT(data);
          // setIsLoading(false);
        // contractAddress.forEach(async (item: string) => {
        // for (let indexs = 0; indexs < contractAddress.length; indexs++) {
        //   const contract_addr = contractAddress[indexs];
        //   const contract = await new web3.eth.Contract(ABI, contract_addr);

        //   try {
        //     const result = await contract.methods
        //     .balanceOf(user.wallet.address)
        //     .call();

        //   if (result) {
        //     for (let index = 0; index < result; index++) {
        //       const token_id = await contract.methods
        //         .tokenOfOwnerByIndex(user.wallet.address, index)
        //         .call();
        //       let res = await getNFTMetadata(contract, token_id);
        //       if (res) {
        //         contractData = [...contractData, res];
        //       }
        //     }
        //   }
        //   } catch (error) {
        //     console.log(error);
            
        //   }
        
        //   // tokenByIndex
        //   // tokenOfOwnerByIndex

        //   // if (result) {
        //   //   console.log(contractAddress);

        //   //   for (let index = 0; index < result.length; index++) {
        //   //     const token_id = result[index];
        //   //     let res = await getNFTMetadata(contract, token_id);
        //   //     if (res){
        //   //        contractData = [...contractData, res];
        //   //     }

        //   //   }
        //   // }
        // }

        // setImage(contractData);
        setLoading(false);
      }
    };

    if (contractAddress) initFetchNFT();
    return () => {};
  }, [contractAddress]);

  const fetchNFT = async (items: any) => {
    let obj: any = [];

    const contract = await new web3.eth.Contract(ABI, items);

    try {
      const result = await contract.methods
      .balanceOf(user.wallet.address)
      .call();

    if (result) {
      for (let index = 0; index < result; index++) {
        const token_id = await contract.methods
          .tokenOfOwnerByIndex(user.wallet.address, index)
          .call();
        let res = await getNFTMetadata(contract, token_id);
        if (res) {
          obj.push(res);
        }
      }
    }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    return obj;
  }

  // interval check NFT on explorer
  let timer: any = 0;
  let timer1: any = 0;
  let timer2: any = 0;
  useEffect(() => {
    if (isRunning)
      timer = setInterval(async () => await getTokenUserActive(users), 3000);
    if (isRunning)
      timer1 = setInterval(async () => await checkGasRequire(users), 300000);
    if (isRunning)
      timer2 = setInterval(async () => await refreshToken(), 180000);

    refreshToken();
    return () => {
      clearInterval(timer);
      clearInterval(timer1);
      clearInterval(timer2);
    };
  }, [isRunning, users]);

  const getTokenUserActive = async (item: any) => {
    await axios
      .get(
        `https://cnx-explorer-ccoin.leafbot.io/api?module=account&action=tokenlist&address=${item.wallet.address}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        const data = response.data.result;

        var contract = data.map(function (item: { contractAddress: any }) {
          return item.contractAddress;
        });

        setContractAddress(contract);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  const checkGasRequire = async (user: any) => {
    const balance = await getBalance(user.wallet.address);
    const total: any = parseFloat(balance[0].balance).toFixed(2);
    if (total < 0.5) {
      await pushEvent();
    }
  };

  async function getNFTMetadata(contract: any, tokenID: string) {
    const result = await contract.methods.tokenURI(tokenID).call();

    const ipfsURL = addIPFSProxy(result);
    if (ipfsURL) {
      try {
        const request: any = new Request(ipfsURL);
        const response = await fetch(request);
        const metadata = await response.json();
        const image: string = addIPFSProxy(metadata.image);
        const data: any = {
          ...metadata,
          image,
          tokenID,
          contractAddress: contract._address,
        };

        return data;
        // setImage(old => [...old, data])
      } catch (error) {
        console.log(error);
      }
    }
  }

  const filterImg = (value: any) => {
    if (value) {
      setFilter(value);
    }

    setAnchorEl(null);
  };

 
  if (!user || loading) return <Loader />;

  //filter unique image
  let imgFilters: any = Image;
  let imgFiltersData: any = [];
  if (filter && filter !== "All") {
    // imgFilters = Array.from(new Set(Image.map((item:any) => `${item.name}${item.tokenID}`))).map( Name=> { return Image.find(obj => `${obj.name}${obj.tokenID}` === Name ) } )
    imgFiltersData = imgFilters.filter(
      (x: { creator: any }) => x.creator === filter
    );
  }

  const imgCDS = imgFilters.filter(
    (x: { creator: string }) => x.creator === "CDS"
  );
  const imgB2S = imgFilters.filter(
    (x: { creator: string }) => x.creator === "B2S"
  );

  const imgTOPS = imgFilters.filter(
    (x: { creator: string }) => x.creator === "TOPS"
  );
  const imgCVERSE = imgFilters.filter(
    (x: { creator: string }) => x.creator === "CVERSE"
  );
  const imgMETAWARDEN = imgFilters.filter(
    (x: { creator: string }) => x.creator === "METAWARDEN"
  );

  return (
    <>
      {/* <Personal user={user} showProfile={false}/> */}
      <div className={styles.frameDiv5}>
        <img
          className={styles.blackFridayCompositionWithIcon}
          alt="redeem"
          src="black-friday-composition-with-black-gifts.svg"
        />
        <div className={styles.frameDiv6}>
          <div className={styles.frameDiv7}>
            <div className={styles.frameDiv8}>
              <b className={styles.redeemAVoucher}>Redeem Code?</b>
            </div>
            <div className={styles.frameDiv9}>
              <div className={styles.redeemYourBenefitVoucherOn}>
                {/* Redeem your Benefit Voucher on attractive deals */}
              </div>
            </div>
          </div>
          <div className={styles.frameDiv10}>
            <div className={styles.frameDiv8} onClick={goToRewards}>
              <b className={styles.redeemB}>REDEEM</b>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.landingPageDiv}>
        <div className={styles.frameDiv}>
          <div className={styles.frameDiv1}>
            <div className={styles.myCollectionTitleDiv}>
              <div className={styles.titleContainerDiv}>
                <div className={styles.titleDiv}>
                  <b className={styles.myCollectionsTitle}>My Collection</b>
                  <span className={styles.textItem}>
                    &nbsp;({imgFilters.length} items)
                  </span>
                </div>
                <Button
                  className={styles.seeAllDiv}
                  sx={{ p: 0 }}
                  variant="text"
                  // color="error"
                  onClick={handleClick}
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  endIcon={<ExpandMoreIcon />}
                >
                  {FILTER[filter]}
                </Button>
                <Menu
                  className={classes.menu}
                  sx={{
                    color: "red",
                  }}
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={() => filterImg("All")}>
                    All&nbsp;<small>({imgFilters.length} items)</small>
                  </MenuItem>
                  <MenuItem onClick={() => filterImg("CDS")}>
                    Central&nbsp;<small>({imgCDS.length} items)</small>
                  </MenuItem>
                  <MenuItem onClick={() => filterImg("B2S")}>
                    B2S&nbsp;<small>({imgB2S.length} items)</small>
                  </MenuItem>
                  <MenuItem onClick={() => filterImg("TOPS")}>
                    Tops&nbsp;<small>({imgTOPS.length} items)</small>
                  </MenuItem>
                  <MenuItem onClick={() => filterImg("CVERSE")}>
                    C-verse&nbsp;<small>({imgCVERSE.length} items)</small>
                  </MenuItem>
                  <MenuItem onClick={() => filterImg("METAWARDEN")}>
                    Metawarden&nbsp;
                    <small>({imgMETAWARDEN.length} items)</small>
                  </MenuItem>
                  {/* <MenuItem onClick={handleClose}>CRG&nbsp;<small>(0 items)</small></MenuItem> */}
                </Menu>
              </div>
              <div className={styles.borderDiv} />
            </div>
          </div>

          <div className={styles.frameDiv2}>
            <Card
              image={filter && filter !== "All" ? imgFiltersData : imgFilters}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
