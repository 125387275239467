import { FunctionComponent,useEffect, useState } from "react";

import styles from "../css/CountDown.module.css";
import { useCountdown } from "./useCountdown";

type CountDownType = {
  targetDate?: any
  setOpening? :any
};

const ExpiredNotice = () => {
  return (
    <div>
      <p className={styles.textSize}>OPEN NOW</p>
    </div>
  );
};


const ShowCounter = ({ days, hours, minutes, seconds }:any) => {
  return (
    <div>
      <p>
          <span className={styles.textSize}>{days}</span>
          <span className={styles.textSize}> DAYS  </span>
          <span className={styles.textSize}>{hours}</span>
          <span className={styles.textSize}> HOURS </span>
          <span className={styles.textSize}>{minutes}</span>
          <span className={styles.textSize}> MINUTES </span>
          <span className={styles.textSize}>{seconds}</span>
          <span className={styles.textSize}> SECONDS </span>
      </p>
    </div>
  );
};

export const CountdownTimer = ({ targetDate,setOpening }:CountDownType) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);
  useEffect(() => {
    if (days + hours + minutes + seconds <= 0) {
      setOpening(false)
    }
  
    return () => {}
  }, [days, hours, minutes, seconds])
  

  if (days + hours + minutes + seconds <= 0) {
    return <ExpiredNotice />;
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  }
};
