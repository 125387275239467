import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import styles from "./Header.module.css";
import { QrReader } from 'react-qr-reader';
import { Modal,Box } from "@mui/material";
import React from "react";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from "recoil"; 
import { usersAtom } from "../../../../src/state";

import img from "../../Image/index"

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  p:0,
};

type HeaderType = {
  navigate?: any
};

export const Header: FunctionComponent<HeaderType> =({navigate})=> {
  const location = useLocation();
  const pathname = window.location.pathname;
  const user:any = useRecoilValue(usersAtom);

  const goHome = ()=>{
    navigate('/');
  }

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    if (pathname !== '/profile')  navigate('/profile');
    if (pathname === '/profile')  navigate(-1);

  };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  
  return (
    <div className={styles.header1Div}>
      <div className={styles.frameDiv}>
        <div className={styles.frameDiv1}>

          <div className={styles.headerLogoDiv} onClick={goHome}>
            <div className={styles.frameDiv2}>
              <div className={styles.frameDiv3}>
                <img className={styles.vectorIcon} alt="" src="/logonew10.svg" />
              </div>
              {/* <div className={styles.cENTRALMETAWALLET}>
                  <img src="central-meta-wallet.svg" alt="" />
              </div> */}
            </div>
          </div>

        </div>
        <div className={styles.frameDiv4}>
          {/* <div className={styles.frameDiv5} onClick={handleOpen}>
            <div className={styles.frameA}>
                <CameraAltIcon   className={styles.frameIcon}/>
            </div>
          </div> */}
          {/* <div className={styles.frameDiv5} onClick={handleOpen}>
            <div className={styles.frameDiv6}>
              <img
                className={styles.icbaselineRedeemIcon}
                alt=""
                src="icbaselineredeem.svg"
              />
            </div>
          </div> */}
          {/* <Link className={styles.frameA} to="/rewards">
            <img className={styles.frameIcon} alt=""  src="icbaselineredeem.svg" />
          </Link> */}
          {/* {location.pathname === '/profile' ?    
            <Link className={styles.frameA} to="/">
              <CloseIcon   className={styles.frameIcon}/>
            </Link>
            : 
            <Link className={styles.frameA} to="/profile">
              <MenuIcon   className={styles.frameIcon}/>
            </Link>
          } */}
          {user ? <div onClick={() => handleOpen()}><MenuIcon  className={styles.frameIcon}/></div>:null}
          {/* <Link className={styles.frameA} onClick={() => handleOpen()} to={"#"}>
              <MenuIcon  className={styles.frameIcon}/>
          </Link> */}

          
        </div>
      </div>

      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style}}>
          <QrReader
              onResult={(result:any, error) => {
                if (!!result) {
                  // setAddress(result.text);
                  // setShowModal(false);
                }
                
                if (!!error) {
                  console.info(error);
                }
              }}
              containerStyle={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: `450px`,
                height:`330px`,
                overflow: "hidden",
                // border: `4px solid ${isValidData ? "green" : "red"}`,
                // borderRadius: "4px",
              }}
              constraints={{
                facingMode: "environment",
              }}
              // className="shadow"
            />
        </Box>
      </Modal> */}

    </div>
  );
};
